// All client side env variable must start with NEXT_PUBLIC_. Otherwise, it's for server side
//  and it won't be available on browser.
export const NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY =
  process.env.NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_CURRENCY

export const currencySymbol = {
  GBP: '£',
  AUD: 'A$',
  NZD: 'NZ$',
  CAD: 'CA$',
  USD: '$',
  EUR: '€',
}

export const NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY =
  process.env.NEXT_PUBLIC_BIGCOMMERCE_CHANNEL_COUNTRY

export const NEXT_PUBLIC_PRICELIST_ID =
  process.env.NEXT_PUBLIC_PRICELIST_ID || '1'

export const NEXT_PUBLIC_DOMAIN_NAME =
  process.env.NEXT_PUBLIC_DOMAIN_NAME || 'noissue.co'

export const NEXT_PUBLIC_TAX_TYPE = process.env.NEXT_PUBLIC_TAX_TYPE

export const BCOrderIdPrefix = process.env.NEXT_PUBLIC_BC_ORDER_ID_PREFIX || '7'

export const NEXT_PUBLIC_GOOGLE_FONTS_API_KEY =
  process.env.NEXT_PUBLIC_GOOGLE_FONTS_API_KEY

export const regionsWithStrictOptInPolicy = {
  US: false,
  NZ: false,
  AU: false,
  GB: true,
  CA: false,
  ROW: false,
  EU: true,
}

export const NET_TERMS_APPLICATION_LINK =
  process.env.NEXT_PUBLIC_NET_TERMS_APPLICATION_LINK

export const SIZE_GUIDELINES_LINK =
  'https://help.noissue.co/hc/en-us/articles/35543383753241-Product-Size-Diagrams-Index'
export const COLOR_GUIDELINES_LINK =
  'https://help.noissue.co/hc/en-us/articles/31546775237785-Your-guide-to-color-at-noissue'

export const HUBSPOT_LP_ORIGIN = 'https://shop.noissue.co'
export const HUBSPOT_EMBED_EVENT = 'resizeIframe'
